export type ValidateFunction = () => boolean;
export type UIResourceBehaviorType = 'visible' | 'interactive';
export type UIResourcePermission = `${string}.${UIResourceBehaviorType}`;

export interface PermissionsRead<TPermissionName extends string> {
  has(permissionName: TPermissionName): boolean;
}

export interface PermissionsDefine<
  TPermissionName extends string,
  PermissionRule,
> {
  set(permissionName: TPermissionName, permissionRule: PermissionRule): void;
  has(permissionName: TPermissionName): boolean;
}

interface PermissionsReadOnlyOptions<TPermissionName> {
  permissions?: Array<TPermissionName>;
}

export class PermissionReadOnly<TPermissionName extends string>
  implements PermissionsRead<TPermissionName>
{
  private store: Set<TPermissionName>;
  constructor({ permissions }: PermissionsReadOnlyOptions<TPermissionName>) {
    this.store = new Set<TPermissionName>(permissions);
  }
  has(permissionName: TPermissionName): boolean {
    if (!permissionName) {
      throw new TypeError('Parameter "permissionName" not provided');
    }
    return this.store.has(permissionName);
  }
}

export class Permissions<TPermissionName extends string>
  implements
    PermissionsRead<string>,
    PermissionsDefine<string, ValidateFunction>
{
  private store: Map<TPermissionName, boolean | ValidateFunction>;
  constructor() {
    this.store = new Map();
  }
  has(permissionName: TPermissionName): boolean {
    if (!permissionName) {
      throw new TypeError('Parameter "permissionName" not provided');
    }

    const validation = this.store.get(permissionName);
    return typeof validation === 'function'
      ? validation()
      : validation ?? false;
  }

  set(permissionName: TPermissionName, validation: boolean | ValidateFunction) {
    if (!permissionName) {
      throw new TypeError('Parameter "permissions" not provided');
    }
    if (typeof validation !== 'function' && typeof validation !== 'boolean') {
      throw new TypeError(
        'Parameter "validation" is not a function or boolean',
      );
    }
    this.store.set(permissionName, validation);
  }
}
