import { createTranslateFunction } from '../progressiveInitialLoader/lib/translate';

const LABELS = [
  'FTE_tour_ed_to_ed2_loader_step_one',
  'FTE_tour_ed_to_ed2_loader_step_two',
  'FTE_tour_ed_to_ed2_loader_step_three',
  'FTE_tour_ed_to_ed2_loader_step_four',
  'FTE_tour_ed_to_ed2_loader_step_five',
];
const STEP_TIME = 6;

function getAnimationCss({
  steps,
  animationClassName,
  animationName = 'loaderStepsAnimation',
  animatedProp = 'content',
  frameDuration = STEP_TIME,
  showContent = true,
  styles = {},
}: {
  steps: string[];
  animationClassName: string;
  animationName: string;
  animatedProp?: string;
  frameDuration?: number;
  showContent?: boolean;
  styles?: Record<string, string>;
}) {
  const frames = steps.map((step: string, index: number) => ({
    percentage: Math.ceil((index / (steps.length - 1)) * 100),
    content: showContent ? `"${step}"` : step,
  }));

  const stylesString = Object.entries(styles).reduce(
    (acc, cur) => `${acc}${cur.join(':')};`,
    '',
  );

  return `
    .${animationClassName}:before {
      display: flex;
      content: "";
      animation: ${animationName} ${steps.length * frameDuration}s both;
      justify-content: center;
      ${stylesString}
    }
    @keyframes ${animationName} {
      ${frames
        .map(
          (frame) =>
            `${frame.percentage}% { ${animatedProp}: ${frame.content}; }`,
        )
        .join('\n')}
    }
  `;
}
const addLabels = async (container: HTMLDivElement) => {
  const labelContainer = document.createElement('div');
  labelContainer.classList.add('preloader-step-label');

  // @ts-expect-error
  import('json!langs').then((translations) => {
    container.appendChild(labelContainer);

    const translate = createTranslateFunction(translations);
    const steps = LABELS.map(translate);
    const css = getAnimationCss({
      steps,
      animationClassName: 'preloader-step-label',
      animationName: 'stepsLabelsAnimation',
      styles: {
        'white-space': 'nowrap',
        'font-size': '18px',
        'line-height': '24px',
        'font-weight': '700',
      },
    });
    container.insertAdjacentHTML('afterbegin', `<style>${css}</style>`);
  });
};

export const addTimeLine = (container: HTMLDivElement) => {
  const stepMeter = document.createElement('div');

  container.appendChild(stepMeter);
  stepMeter.classList.add('preloader-step-meter');

  const css = getAnimationCss({
    steps: ['0', '20%', '40%', '60%', '80%', '98%'],
    animationClassName: 'preloader-step-meter',
    animationName: 'timeLineAnimation',
    animatedProp: 'width',
    showContent: false,
    styles: {
      height: '6px',
      background: '#116dff',
      width: '0',
      'animation-delay': '1s',
    },
  });
  container.insertAdjacentHTML('afterbegin', `<style>${css}</style>`);
};

export const createSectionsMigrationInitialLoader = () => {
  const stepsContainer = document.createElement('div');
  stepsContainer.classList.add('preloader-step-container');

  window.document.body.appendChild(stepsContainer);

  addTimeLine(stepsContainer);
  addLabels(stepsContainer);

  return {
    unmount: () => {
      stepsContainer?.parentNode?.removeChild(stepsContainer);
    },
  };
};
