import { getCookie } from './utils';
import type { EditorModel, ValuesOfReturnFnValues } from 'types/core';
import type {
  BiLoggerPerformanceMemoryParams,
  BiLoggerInitialParams,
  BiLoggerEditor2Params,
} from 'types/bi';
import type { EditorParams } from '@/editorParams';

const getEditor2BIParams = (
  editorParams: EditorParams,
): ValuesOfReturnFnValues<BiLoggerEditor2Params> => {
  const SITE_CREATION_TEMPLATE_ID = '6e51b1b9-0fa7-42df-9637-771a4543f4f4';
  return {
    isSiteCreation:
      editorParams.originalTemplateId === SITE_CREATION_TEMPLATE_ID ||
      editorParams.siteCreationWizard,
    isSection: editorParams.isSectionsExperienceEnabled,
  };
};

const getBsiParams = () => {
  const bsiCookie = getCookie('bSession');

  if (!bsiCookie) {
    return {};
  }

  const [bsi, bsi_page_number] = bsiCookie.split('|');

  return {
    browsing_session: bsi,
    bsiPageNumber: bsi_page_number,
    bsi: bsiCookie,
  };
};

const getPerformanceMemoryParams =
  (): ValuesOfReturnFnValues<BiLoggerPerformanceMemoryParams> => {
    const performance = window?.performance as Performance & {
      memory?: {
        totalJSHeapSize: number;
        usedJSHeapSize: number;
        jsHeapSizeLimit: number;
      };
    };

    const toString = (value: any): string | null | undefined =>
      value ? String(value) : value;

    // NOTE: performance.memory is a non-standard extension of chrome
    // https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory#browser_compatibility
    if (performance?.memory) {
      return {
        totalJsHeapSize: () => toString(performance?.memory?.totalJSHeapSize),
        usedJsHeapSize: () => toString(performance?.memory?.usedJSHeapSize),
        jsHeapSizeLimit: () => toString(performance?.memory?.jsHeapSizeLimit),
      };
    }

    return {};
  };

export const getBILoggerInitialParams = ({
  editorModel,
  editorParams,
}: {
  editorModel: EditorModel;
  editorParams: EditorParams;
}) => {
  const { editorBase, permissionsInfo } = editorModel;

  const params: ValuesOfReturnFnValues<BiLoggerInitialParams> = {
    ownerId: permissionsInfo?.ownerId,
    // TODO: do we need this 'ownerId` duplication?
    // @yinonc
    // > you need uuid in our BI for validation needs (BI is invalid without uuid)
    uuid: permissionsInfo?.ownerId,
    roles: permissionsInfo?.loggedInUserRoles
      .map((userRole: any) => userRole.role)
      .join(','),
    // TODO: esi is param sent by default, remove esi from the event sending places:
    // https://github.com/wix-private/santa-editor/blob/5302299edf2dab9e21de8156d6e9195d60fa2db2/santa-editor/packages/compPanels/panels/languageSelector/languageSelectorManagePanelMapper.ts#L38
    // https://github.com/wix-private/santa-editor/search?q=esi
    esi: editorParams.esi,
    is_rollout: editorParams.isRollout,
    viewerName: editorParams.viewerName,
    editor_version: editorBase.split('/').pop() || editorBase,
    ts: () => Math.round(Date.now() - (window.mainLoaded || 0)),
    msid: editorParams.metaSiteId,
    site_id: editorParams.siteId,
    ...getBsiParams(),
    ...getPerformanceMemoryParams(),
    ...getEditor2BIParams(editorParams),
  };

  return params;
};
