/// <reference types="webpack-env" />
import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils';
import getSubdomain from '@wix/santa-main-r/lib/lib/common/getSubdomain';
import identifyForFullStory from './identifyForFullStory';
import instrument from './instrument';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { createEditorLoggers } from './editorLoggers';
import joinURL from '@wix/santa-main-r/lib/lib/common/joinURL';
import overrideScriptsLocationMapFromQuery from '@wix/santa-main-r/lib/lib/common/overrideScriptsLocationMapFromQuery';
import storageUtil from '@wix/santa-main-r/lib/lib/common/storageUtil';
import { setupRequire } from './require-config';
import requirejs from 'requirejs';
import { requirePromise, StartupConfig } from '../common';
import { loadCss } from './loadCss';
import { renderPreview } from './renderIframe/renderIframe';
import { getCSSPaths } from './get-rjs-paths';
import { initEditor } from '../entry';
import {
  getBaseVersion,
  registerToTabLeaveEvent,
  getBaseUIOverrides,
} from './utils';
import { htmlLoadingStepMainRLoaded } from '@wix/bi-logger-editor/v2';
import './jsonWithFallback';
const { versions } = require('../../../gen/options.json');
import experiment from './experiment';
import { Permissions, PermissionReadOnly } from './permissions';
import { createHook, createPromiseHook } from './hooks';
import { createErrorEventProcessor } from './errorEventProcessor';

import type { EditorParams } from '@/editorParams';
import type { BaseHooks, CssMapping } from './types';

import { getSectionsMigrationFlow } from './sectionsMigration';
import { initTabDuplicatePolice } from './tabDuplicatePolice';
import { initHealthCheck } from './initHealthCheck';
import { getIsSectionsExperienceEnabled } from './sectionsExperience';
import { registerToTabSwitchEvent } from './utils/registerToTabSwitchEvent';
import {
  getInitialLoaderMode,
  initBaseInitialLoader,
  initProgressiveInitialLoader,
} from './initialLoader';

import type {
  PermissionsRead,
  PermissionsDefine,
  ValidateFunction,
  UIResourcePermission,
} from './permissions';

export interface EditorPermissions {
  root: PermissionsRead<string>;
  uxResource: PermissionsDefine<UIResourcePermission, ValidateFunction>;
}

const queryUtil = getQueryUtils(window);

window.define('experiment', [], () => experiment);
window.define('bluebird', [], () => Promise);
window.define('@sentry/browser', [], () => (window as any).Sentry);

window.joinURL = joinURL;
window.queryUtil = queryUtil;
window.persistent = storageUtil(window); // TODO: how is this used?
window.identifyForFullStory = identifyForFullStory;

if (window.customRenderSite !== undefined) {
  // allows to override this in other html files as starting points
  window.customRenderSite();
}

function applyTopologyOverriding() {
  const versionRegExp = /1\.\d+\.\d+/g;
  window.serviceTopology.scriptsLocationMap['santa-langs'] =
    window.serviceTopology.scriptsLocationMap['santa-langs'].replace(
      versionRegExp,
      versions['santa-langs'],
    );
  window.serviceTopology.scriptsLocationMap =
    overrideScriptsLocationMapFromQuery(
      window.serviceTopology.scriptsLocationMap,
      queryUtil.getParameterByName('scriptsLocations'),
    );
}

const isAddress = function isAddress(str: AnyFixMe) {
  return /^(https?:)?\/\//.test(str);
};

const isLocal = (path: string) =>
  /^(https?:)?\/\/localhost|local.wix.com($|[/:])/.test(path);

async function provideExternalsToModuleFederation(cssMapping: CssMapping) {
  await __webpack_init_sharing__('default');

  const requirejsOverride = {
    '@wix/editor-platform-host-integration/repluggable':
      'editor-platform-host-integration',
    '@wix/extensions-slots-core': 'extensions-slots-core', //because it's built with named amd export (define("extensions-slots-core", ["react", "reactDOM"], factory);)
  };

  for (const moduleName of __REQUIREJS_EXTERNALS__) {
    __webpack_share_scopes__.default[moduleName] = {
      '1.0.0': {
        eager: false,
        from: '@wix/santa-editor',
        get: async () => {
          const moduleToImport =
            requirejsOverride[moduleName as keyof typeof requirejsOverride] ||
            moduleName;
          const modPromise = requirePromise([moduleToImport]);
          const cssPromises = (cssMapping[moduleToImport] || []).map(loadCss);
          const [resModule] = await Promise.all([modPromise, ...cssPromises]);
          return () => resModule;
        },
      },
    };
  }
}

async function provideSharedToRequirejs() {
  // TODO: cleanup when all deps migrated to the `@wix/` scope (`@wix/santa-editor-symbols`, `@wix/santa-editor-utils`, etc..)
  // https://wix.slack.com/archives/C01QVAD2UP2/p1614869864037300?thread_ts=1614860977.003700&cid=C01QVAD2UP2
  const TEMP_WIX_SCOPED_MAP = {
    'wix-base-ui': '@wix/wix-base-ui',
    'wix-ui-santa': '@wix/wix-ui-santa',
    'santa-editor-utils': '@wix/santa-editor-utils',
    'santa-editor-symbols': '@wix/santa-editor-symbols',
  };

  const origLoad = requirejs.load;

  const requireJSExternalsSet = new Set(__REQUIREJS_EXTERNALS__);

  requirejs.load = (ctx: AnyFixMe, name: AnyFixMe, ...args: AnyFixMe[]) => {
    if (requireJSExternalsSet.has(name)) {
      return origLoad(ctx, name, ...args);
    }

    const scope = __webpack_share_scopes__.default;
    if (scope) {
      const keys = Object.keys(scope);

      const packageNameToLoad =
        TEMP_WIX_SCOPED_MAP[name as keyof typeof TEMP_WIX_SCOPED_MAP] || name;
      const packageNameToDefine = name;

      if (keys.includes(packageNameToLoad)) {
        const versions = Object.keys(scope[packageNameToLoad]);
        if (versions.length !== 1) {
          console.error(
            `Package: ${packageNameToLoad} has multiple versions defined: ${versions}`,
          );
        }
        scope[packageNameToLoad][versions[0]]
          .get()
          .then((factory: AnyFixMe) => {
            window.define(packageNameToDefine, factory);
            ctx.completeLoad(packageNameToDefine);
          });
        return;
      }
    }

    return origLoad(ctx, name, ...args);
  };
}

function patchRequireJsCrossOrigin() {
  const originalRequireJsCreateNode = requirejs.createNode;
  requirejs.createNode = (...args: any[]) => {
    const node = originalRequireJsCreateNode(...args);
    node.setAttribute('crossorigin', 'anonymous');
    return node;
  };
}

function getBaseUrl(
  name: string,
  base: string | undefined,
  serviceTopology: AnyFixMe,
) {
  if (!base) {
    return serviceTopology.scriptsLocationMap[name];
  }
  if (isAddress(base)) {
    return base;
  }
  return joinURL(serviceTopology.scriptsDomainUrl, 'services', name, base);
}

//used by editorx bridge
export async function startupResponsive({
  publicPath,
}: {
  publicPath: string;
}) {
  // window.__dont_inject_css_extract_plugin__ = true;
  window.__webpack_overridable_esm_modules__ = true; //used by AllowMutateEsmExports plugin

  return _startup({
    isInsideEditorX: true,
    loadMainCss: false,
    measureFrameRate: false,
    shouldBiErrorsAndFedops: false,
    shouldRender: false,
    shouldLoadPolyfills: false,
    publicPath,
    stylablePanelTheme: 'editorx-theme',
  });
}

export async function startup({ publicPath }: { publicPath: string }) {
  return _startup({
    isInsideEditorX: false,
    loadMainCss: true,
    measureFrameRate: true,
    shouldBiErrorsAndFedops: true,
    shouldRender: true,
    shouldLoadPolyfills: true,
    publicPath,
  });
}

async function _startup(config: StartupConfig) {
  const { shouldBiErrorsAndFedops, publicPath } = config;

  __webpack_public_path__ = publicPath;

  window.afterEditorPrefetch = false; //TODO remove this;

  const {
    loggerModel: biLoggerModel,
    editorModel,
    appStudioModel,
    serviceTopology,
  } = window;
  const { isTabDuplicated } = initTabDuplicatePolice();
  const isInsideEditorX = config.isInsideEditorX;
  const isInsideAppStudio = appStudioModel !== undefined;

  const isSectionsExperienceEnabled = getIsSectionsExperienceEnabled({
    isInsideEditorX,
    isInsideAppStudio,
    editorModel,
    experiment,
  });

  const sectionsMigrationFlow = isSectionsExperienceEnabled
    ? undefined
    : getSectionsMigrationFlow({
        isInsideEditorX,
        isInsideAppStudio,
        editorModel,
        experiment,
        queryUtil,
      });

  // TODO: use `editorParams` instead of `queryUtil.getParameterByName('x')` across the whole code
  const editorParams: EditorParams = {
    isInsideEditorX,
    isInsideAppStudio,
    isSectionsExperienceEnabled,
    sectionsMigrationFlow,
    baseUIOverrides: getBaseUIOverrides(
      queryUtil.getParameterByName('baseUIOverrides'),
    ),
    isLocal: isLocal(queryUtil.getParameterByName('EditorSource')),
    isDebug:
      queryUtil.getParameterByName('debug') ||
      localStorage?.getItem('editor-debug'),
    isQA: queryUtil.isParameterTrue('isqa'),
    siteCreationWizard:
      queryUtil.getParameterByName('siteCreationWizard') === 'true' &&
      editorModel.isSiteCreationEligible,
    originalTemplateId: editorModel.siteHeader.originalTemplateId,
    isTestRunner: queryUtil.isParameterTrue('istestrunner'),
    isBiErrorsAndFedopsEnabled:
      !queryUtil.isParameterTrue('suppressbi') &&
      !queryUtil.isParameterTrue('isqa') &&
      shouldBiErrorsAndFedops,
    isRollout: biLoggerModel.is_rollout === 1, // 0 - false, 1 - true
    shouldMeasureFrameRate: config.measureFrameRate,
    viewerName: biLoggerModel.origin,
    esi: editorModel.editorSessionId,
    siteId: editorModel.siteHeader?.id,
    metaSiteId: editorModel.metaSiteId || biLoggerModel.metaSiteId,
    isTabDuplicated,
  };

  // TODO: pass base hooks to the `editorCore` module
  const hooks: BaseHooks = {
    tabChanged: createHook(),
    siteCreationCreatePresets: createHook(),
    editorBuildAndRender: createPromiseHook(),
  };

  if (editorModel) {
    editorModel.languageCode =
      queryUtil.getParameterByName('lang') || editorModel.languageCode;

    const baseUrlWithVersion = getBaseUrl(
      'santa-editor',
      window.editorBase,
      serviceTopology,
    );

    editorModel.editorBase = baseUrlWithVersion;
    editorModel.editorVersion = getBaseVersion(baseUrlWithVersion);
  }

  const editorLoggers = createEditorLoggers({
    biLoggerModel,
    editorParams,
    editorModel,
    options: {
      useBatch: true,
    },
  });

  const fedopsLogger = editorLoggers.fedops.logger;
  const biLogger = editorLoggers.bi.logger;

  initHealthCheck({
    hooks,
    biDefaults: editorLoggers.bi._initialDefaults,
    editorParams,
  });

  ErrorReporter._addEventProcessor(createErrorEventProcessor({ fedopsLogger }));

  window.mainLoaded = Date.now();
  fedopsLogger.appLoadingPhaseStart('main-chunk');

  applyTopologyOverriding();

  setupRequire({ editorModel, local: editorParams.isLocal, versions });

  patchRequireJsCrossOrigin();

  renderPreview({
    experiment,
    editorModel,
    editorParams,
    serviceTopology,
    fedopsLogger,
  });

  instrument({
    window,
    requirejs,
    experiment,
    queryUtil,
    editorLoggers,
    editorModel,
    editorParams,
  });

  registerToTabSwitchEvent({
    experiment,
    editorLoggers,
    hooks,
  });

  registerToTabLeaveEvent({ experiment, editorLoggers });

  if (editorModel) {
    const { performance } = window;
    const navigation = performance ? performance.navigation : undefined;
    const navigationType = navigation ? navigation.type : undefined;
    //newer implementation window.performance.getEntriesByType("navigation")[0].type;

    if (experiment.isOpen('se_moveBiLoggerToEditor')) {
      void biLogger.report(
        htmlLoadingStepMainRLoaded({
          navigationType,
        }),
      );
    } else {
      //MAIN_R_LOADED evt
      window.bi.event(
        { evid: 458 },
        {
          // main-r loaded event
          prefetch: window.afterEditorPrefetch,
          esi: editorParams.esi,
          msid: editorParams.metaSiteId,
          navigationType,
        },
      );
    }
  }

  //eslint-disable-next-line @wix/santa/no-falsy-experiment
  if (!experiment.isOpen('dm_immutableDomain')) {
    try {
      window.document.domain = getSubdomain(window.document.domain);
    } catch (e) {
      // empty
    }
  }

  const cssMapping = getCSSPaths({
    editorModel,
    stylablePanelTheme: config.stylablePanelTheme,
  });

  await __webpack_init_sharing__('default');
  window.__debug_project_references_scopes__ = __webpack_share_scopes__;
  await provideSharedToRequirejs();
  await provideExternalsToModuleFederation(cssMapping);

  const initialLoaderMode = getInitialLoaderMode({
    editorParams,
    experiment,
  });

  const initialLoader =
    initialLoaderMode === 'progressive'
      ? initProgressiveInitialLoader({
          hooks,
          fedopsLogger,
          timelineMode: editorParams.sectionsMigrationFlow ? 'slow' : 'regular',
          // @ts-expect-error
          loadI18n: () => import('json!langs'),
        })
      : initBaseInitialLoader({
          isEditorToEditorMigration:
            editorParams.sectionsMigrationFlow === 'editor',
        });

  window.initialLoader = initialLoader;

  if (editorParams.siteCreationWizard) {
    import('./siteCreation').then(({ renderSiteCreation }) =>
      renderSiteCreation({
        unmountInitialLoader: initialLoader.unmount,
        hooks,
      }),
    );
  }

  const editorPermissions: EditorPermissions = {
    root: new PermissionReadOnly({
      permissions: editorModel.permissionsInfo.permissions,
    }),
    uxResource: new Permissions(),
  };
  const editorBuildAndRenderPromise = initEditor(
    {
      editorLoggers,
      editorModel,
      editorParams,
      editorPermissions,
    },
    config,
  );

  editorBuildAndRenderPromise.then(() => hooks.editorBuildAndRender.resolve());
  editorBuildAndRenderPromise.catch((error) => {
    hooks.editorBuildAndRender.reject(error);

    window.console.error(error);
    ErrorReporter.captureException(error, {
      tags: {
        operation: 'editor-bootstrap',
      },
    });
  });

  const { builtEditor: result, clean } = await editorBuildAndRenderPromise;

  if (module.hot) {
    module.hot.data = { clean };
    const hmr = await (await import('../hot')).initializeHmr();

    module.hot.accept('../entry', async () => {
      hmr.request(async () => {
        module.hot.data.clean();
        const newReditor: typeof import('../entry') = require('../entry');
        const { clean } = await newReditor.initEditor(
          { editorLoggers, editorModel, editorParams, editorPermissions },
          config,
        );
        module.hot.data = { clean };
      });
    });
  }

  fedopsLogger.appLoadingPhaseFinish('main-chunk');
  return result;
}
