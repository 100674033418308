import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils';

const queryUtil = getQueryUtils(window);

function getShouldUseDebugBundle() {
  return (
    queryUtil.getParameterByName('debug')?.includes('all') ||
    localStorage?.getItem('editor-debug')
  );
}
enum ScriptPathes {
  Prod = 'bundle',
  Dev = 'bundle-dev',
}

function isProdScript(currentSrc: string) {
  return currentSrc.includes(`/dist/${ScriptPathes.Prod}/`);
}

function getRedirectPath({
  currentSrc,
}: {
  currentSrc: string;
}): ScriptPathes | null {
  if (!isProdScript(currentSrc)) {
    return null;
  }
  const shouldUseDebugBundle = getShouldUseDebugBundle();

  if (shouldUseDebugBundle) {
    return ScriptPathes.Dev;
  }
  return null;
}

const curScriptElement = document.currentScript as HTMLScriptElement;

function loadRedirectScript(redirectPath: string) {
  const scriptElm = window.document.createElement('script');
  scriptElm.type = 'text/javascript';
  scriptElm.crossOrigin = 'anonymous';
  scriptElm.src = curScriptElement.src.replace(
    `/dist/${ScriptPathes.Prod}/`,
    `/dist/${redirectPath}/`,
  );

  curScriptElement.parentNode.insertBefore(scriptElm, curScriptElement);
  return;
}

const versionRegex = /\/(\d+\.\d+\.\d+)\//;

function hasVersion(url: string) {
  return Boolean(url.match(versionRegex));
}

function replaceVersionWithDist(url: string) {
  return url.replace(versionRegex, '/dist/');
}

function getPublicPath({ currentSrc }: { currentSrc: string }) {
  let publicPath = currentSrc
    .replace('//dist/', '/dist/')
    .replace(/#.*$/, '')
    .replace(/\?.*$/, '')
    .replace(/\/[^\/]+$/, '/'); //copied from webpack runtime - auto public path

  if (
    hasVersion(publicPath) &&
    !queryUtil.getParameterByName('disable_hash_dist')
  ) {
    publicPath = replaceVersionWithDist(publicPath);
  }
  return publicPath;
}

async function bootstrap() {
  const currentSrc = curScriptElement.src;
  const redirectPath = getRedirectPath({ currentSrc });

  if (redirectPath) {
    loadRedirectScript(redirectPath);
    return;
  }

  const publicPath = getPublicPath({ currentSrc });

  if (!(window as any).__sentryIsAlreadInitializedTODORemove) {
    (window as any).Sentry = await import(
      /* webpackMode: "eager" */ '@sentry/browser/esm/index'
    );
    await import(
      // @ts-expect-error
      /* webpackMode: "eager" */ '@wix/editor-error-reporter/bundle/init.bundle'
    );
  }

  const mod = await import(/* webpackMode: "eager" */ './startup');
  await mod.startup({ publicPath });
}

bootstrap().catch((err) => {
  console.error(err);
  throw err;
});
