import type { EditorPermissions, EditorModel, EditorLoggers } from 'types/core';
import type { EditorParams } from '@/editorParams';
import type { StartupConfig } from './common';
import { EditorBootstrapError, EditorBootstrapErrorCode } from './errors';
import { loadMainPackages } from './loadMainPackages';
import type { AppHost } from '@/apilib';
import type { EditorStore } from '@/stateManagement';

export async function initEditor(
  {
    editorLoggers,
    editorModel,
    editorParams,
    editorPermissions,
  }: {
    editorLoggers: EditorLoggers;
    editorModel: EditorModel;
    editorParams: EditorParams;
    editorPermissions: EditorPermissions;
  },
  config: StartupConfig,
) {
  const fedopsLogger = editorLoggers.fedops.logger;
  const { rEditor } = await loadMainPackages(
    { fedopsLogger, editorModel },
    config,
  );

  let builtEditor: { host: AppHost; store: EditorStore };
  try {
    fedopsLogger.appLoadingPhaseStart('editor-build-app');
    builtEditor = await rEditor.editorMain.build({
      editorParams,
      editorLoggers,
      editorPermissions,
    });
    fedopsLogger.appLoadingPhaseFinish('editor-build-app');
  } catch (error) {
    throw new EditorBootstrapError('Failed to build editor', {
      code: EditorBootstrapErrorCode.EditorBuildAppError,
      originalError: error,
    });
  }

  if (config.shouldRender) {
    try {
      fedopsLogger.appLoadingPhaseStart('editor-render-app');
      rEditor.editorMain.render(builtEditor);
      fedopsLogger.appLoadingPhaseFinish('editor-render-app');
    } catch (error) {
      throw new EditorBootstrapError('Failed to render editor', {
        code: EditorBootstrapErrorCode.EditorRenderAppError,
        originalError: error,
      });
    }
  }

  const clean = () => rEditor.editorMain.__unmountEditor();
  return { builtEditor, clean };
}
