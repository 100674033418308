// TODO: resolve `startup/hooks` vs `apilib/hooks` (mb move hooks to the separate package)

export interface Hook<Data, Result> {
  tap(callback: (data: Data) => Result): void;
  fire(data: Data): Result[];
}

export const createHook = <Data = void, Result = void>(): Hook<
  Data,
  Result
> => {
  const taps: Array<(data: Data) => Result> = [];

  const tap: Hook<Data, Result>['tap'] = (callback) => {
    taps.push(callback);
  };

  const fire: Hook<Data, Result>['fire'] = (data) =>
    taps.map((tapCallback) => tapCallback(data));

  return {
    tap,
    fire,
  };
};

export interface PromiseHook<Data = void> {
  promise: Promise<Data>;
  resolve: (value: Data) => void;
  reject: (reason: any) => void;
}

export function createPromiseHook<Data = void>(): PromiseHook<Data> {
  let _resolve: PromiseHook<Data>['resolve'];
  let _reject: PromiseHook<Data>['reject'];

  const promise = new Promise<Data>((resolve, reject) => {
    _resolve = resolve as any;
    _reject = reject;
  });
  return {
    resolve: _resolve,
    reject: _reject,
    promise,
  };
}
