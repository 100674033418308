export enum EditorBootstrapErrorCode {
  LoadPackagesError = 'EditorBootstrap_LoadPackagesError',
  EditorBuildAppError = 'EditorBootstrap_EditorBuildAppError',
  EditorRenderAppError = 'EditorBootstrap_EditorRenderAppError',
}

export class EditorBootstrapError extends Error {
  public code?: EditorBootstrapErrorCode;
  public message: string;

  constructor(
    message: string,
    {
      code,
      originalError,
    }: { code?: EditorBootstrapErrorCode; originalError?: Error | unknown },
  ) {
    super(message);

    this.name = this.constructor.name;

    this.code = code;
    this.message = `${code ? `${code}. ${message}` : `${message}`}${
      originalError && originalError instanceof Error
        ? `\n  - Original error message: ${originalError.message}`
        : ''
    }`;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, EditorBootstrapError);
    }
  }
}
