"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorReporter = void 0;
var Sentry = __importStar(require("@sentry/browser"));
var createMockSentryLogger = function () { return ({
    captureException: console.error,
    captureMessage: console.log,
    setTags: function () { },
    setExtras: function () { },
    addBreadcrumb: function () { },
    breadcrumb: function () { },
    _addEventProcessor: function () { },
}); };
function createSentry() {
    return {
        captureException: function (exception, captureContext) {
            return Sentry.captureException(exception, captureContext);
        },
        captureMessage: function (message, level) {
            return Sentry.captureMessage(message, level);
        },
        setTags: function (tags) {
            Sentry.setTags(tags);
        },
        setExtras: function (extras) {
            Sentry.setExtras(extras);
        },
        addBreadcrumb: function (breadcrumb) {
            Sentry.addBreadcrumb(breadcrumb);
        },
        breadcrumb: function (message, data) {
            if (data === void 0) { data = {}; }
            Sentry.addBreadcrumb({ message: message, data: data });
        },
        _addEventProcessor: function (callback) {
            Sentry.configureScope(function (scope) {
                scope.addEventProcessor(callback);
            });
        },
    };
}
function hasSentry() {
    return Sentry && Boolean(Sentry.captureException); //because of moduleinterop, module is {default: undefined}
}
exports.ErrorReporter = hasSentry()
    ? createSentry()
    : createMockSentryLogger();
