import { ErrorReporter } from '@wix/editor-error-reporter';

import { createSectionsMigrationInitialLoader } from './sectionsMigration';

// NOTE: ensure only type imported. The size of initial loader should be minimal
import type { FedopsLogger } from 'types/fedops';
import type {
  InitialLoaderMode,
  InitInitialLoaderResult,
} from 'types/initialLoader';
import type { Experiment } from 'experiment';
import type { EditorParams } from '@/editorParams';

import type {
  LoaderTimelineMode,
  LoadI18n,
} from './progressiveInitialLoader/types';
import type { BaseHooks } from './types';

export function getInitialLoaderMode({
  editorParams,
  experiment,
}: {
  editorParams: EditorParams;
  experiment: Experiment;
}): InitialLoaderMode {
  const canShowProgressiveLoader =
    !editorParams.isInsideEditorX &&
    !editorParams.isInsideAppStudio &&
    !editorParams.siteCreationWizard;

  if (!canShowProgressiveLoader) {
    return 'base';
  }

  const isEditor2Experience = editorParams.isSectionsExperienceEnabled;
  // NOTE: disable progressive loader for sites with sections migration flow
  // https://wix.slack.com/archives/C01CN7UT34Y/p1656406265632019?thread_ts=1656402116.882559&cid=C01CN7UT34Y
  // editorParams.isSectionsExperienceEnabled ||
  // Boolean(editorParams.sectionsMigrationFlow);

  if (
    experiment.isOpen('se_progressiveLoader') ||
    (experiment.isOpen('se_progressiveLoaderInEditor2') && isEditor2Experience)
  ) {
    return 'progressive';
  }

  return 'base';
}

// NOTE: base initial loader is rendered from the server side
const getLoaderContainer = (): HTMLDivElement =>
  window.document.getElementById('video-preloader') as HTMLDivElement;

export function initBaseInitialLoader({
  isEditorToEditorMigration,
}: {
  isEditorToEditorMigration: boolean;
}): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();

  const sectionsMigrationInitialLoader = isEditorToEditorMigration
    ? createSectionsMigrationInitialLoader()
    : null;

  return {
    unmount: () => {
      loaderContainer?.parentNode?.removeChild(loaderContainer);
      sectionsMigrationInitialLoader?.unmount();
    },
  };
}

const reportError = (error: Error): void => {
  ErrorReporter.captureException(error, {
    tags: {
      operation: 'progressive-initial-loader',
    },
  });
};

export function initProgressiveInitialLoader({
  hooks,
  fedopsLogger,
  timelineMode,
  loadI18n,
}: {
  hooks: BaseHooks;
  fedopsLogger: FedopsLogger;
  timelineMode: LoaderTimelineMode;
  loadI18n: LoadI18n;
}): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();
  const removeLoaderContainer = () => {
    loaderContainer?.parentNode?.removeChild(loaderContainer);
  };

  fedopsLogger.appLoadingPhaseStart('main-chunk-init-initial-loader');

  const mountComponentPromise = import('./progressiveInitialLoader').then(
    async ({ mountLoader }) =>
      mountLoader({
        timelineMode,
        timelinePendingPhases: {
          'leftBar.original': hooks.editorBuildAndRender.promise,
        },
        loaderContainer,
        reportError,
        loadI18n,
      }),
  );

  mountComponentPromise.then(
    () => {
      fedopsLogger.appLoadingPhaseFinish('main-chunk-init-initial-loader');
    },
    (error) => {
      reportError(error);
    },
  );

  return {
    unmount() {
      mountComponentPromise.then(({ unmount }) => {
        unmount();
      });

      removeLoaderContainer();
    },
  };
}
